<template>
    <div class="servewrap">
        <headbox></headbox>
        <div class="indexbox">
            <!-- 滑块 -->
            <div class="swiper-container">
                <div class="swiperbgimg"></div>
                <div class="swiper-wrapper">
                    <div class="swiper-slide">
                      <img class="swiperimg" src="@/assets/img/servebg.jpg">
                    </div>
                </div>
            </div>
        </div>
        <div class="servebox">
          <div class="servetitle">工商服务</div>
          <div class="serverow">
            <div class="serveitem">
              <div class="serveitem_box">
                <img class="serveitembg" src="https://org.modao.cc/uploads4/images/5574/55743407/v2_qilion.jpg" />
                <div class="serveitemtitle">一键开户</div>
              </div>
            </div>
             <div class="serveitem">
              <div class="serveitem_box">
                <img class="serveitembg" src="https://org.modao.cc/uploads4/images/5574/55743407/v2_qilion.jpg" />
                <div class="serveitemtitle">一键开户</div>
              </div>
            </div>
             <div class="serveitem">
              <div class="serveitem_box">
                <img class="serveitembg" src="https://org.modao.cc/uploads4/images/5574/55743407/v2_qilion.jpg" />
                <div class="serveitemtitle">一键开户</div>
              </div>
            </div>
             <div class="serveitem">
              <div class="serveitem_box">
                <img class="serveitembg" src="https://org.modao.cc/uploads4/images/5574/55743407/v2_qilion.jpg" />
                <div class="serveitemtitle">一键开户</div>
              </div>
            </div>
             <div class="serveitem">
              <div class="serveitem_box">
                <img class="serveitembg" src="https://org.modao.cc/uploads4/images/5574/55743407/v2_qilion.jpg" />
                <div class="serveitemtitle">一键开户</div>
              </div>
            </div>
             <div class="serveitem">
              <div class="serveitem_box">
                <img class="serveitembg" src="https://org.modao.cc/uploads4/images/5574/55743407/v2_qilion.jpg" />
                <div class="serveitemtitle">一键开户</div>
              </div>
            </div>
             <div class="serveitem">
              <div class="serveitem_box">
                <img class="serveitembg" src="https://org.modao.cc/uploads4/images/5574/55743407/v2_qilion.jpg" />
                <div class="serveitemtitle">一键开户</div>
              </div>
            </div>
          </div>
        </div>
        <foot></foot>
    </div>
</template>

<script>
import 'swiper/swiper-bundle.css';
import Swiper from 'swiper/bundle'
// import headbox from "@/components/head";
import headbox from "@/components/headfloat";
import foot from '@/components/foot'



export default {
    components:{
        headbox ,
        foot ,
    },
    data() {
        return {
            mySwiper: {}
        }
    },    
    mounted(){
        this.mySwiper = new Swiper('.swiper-container', {
            // initialSlide: 0,
            // direction: 'vertical',
            // effect : 'coverflow',
            mousewheelControl : true,
            autoplay:{
              delay: 2000, 
            },
            on:{
	        	init:function(swiper){
                    console.log(swiper)
                    let slide = swiper.slides[0].classList;
                    console.log(slide)
	        	  	slide.add('ani-slide');
	        	},
	        	transitionStart: function(swiper){
	        	  	for(let i = 0; i < swiper.slides.length; i++){
	        	  	  	let slide = swiper.slides[i].classList;
	        	  	    slide.remove('ani-slide');
	        	  	}
	        	},
	        	transitionEnd: function(swiper){
                    console.log(swiper)
	        	  	let slide = swiper.slides[swiper.activeIndex].classList;
	        	  	slide.add('ani-slide');
	        	},
	        }
        })
    },
}
</script>

<style scoped>

  .servewrap{
    font-size: 12px;
  }

  .indexbox{
    width: 100%;
    overflow-x: hidden;
  }

  .swiper-container {
    width: 100%;
    /* height: 90%; */
    height: auto;
    position: relative;
    overflow: revert;
  }

  .swiperbgimg{
    width: 100%;
    height: 100%;
    /* background-image: url(""); */
    background-size: 100% 100%;
    opacity: 0.5;
    position: absolute;
    top: 0px;
    left: 0px;
  }

  .swiper-slide {
    font-size: 18px;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .swiperimg{
    width: 100%;
    height: 100%;
  }

  .servebox{
    width: 100%;
    box-sizing: border-box;
    padding: 30px;
    background-color: rgba(232,232,232,0.5);
  }

  .servetitle{
    width: 100%;
    font-size: 15px;
    font-weight: 800;
  }

  .serverow{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
    padding: 0px 5%;
    margin: 20px 0px;
  }

  .serveitem{
    width: 16%;
    box-sizing: border-box;
    font-size: 0px;
    margin: 20px 0.3%;
    display: flex;
    justify-content: center;
  }

  .serveitem_box{
    width: 149px;
    box-shadow: 1px -1px 5px rgba(0, 0, 0, 0.5);
  }

  .serveitembg{
    width: 149px;
    height: auto;
    /* box-shadow: 1px -1px 5px rgba(0, 0, 0, 0.5); */
  }

  .serveitemtitle{
    width: 149px;
    line-height: 38px;
    text-align: center;
    background-color: #FFFFFF;
    font-size: 12px;
    /* box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.5); */
    text-overflow: ellipsis;
    letter-spacing: 2px;
    display: inline-block;
  }

</style>
